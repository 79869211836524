import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { signin, googleSignin, add } from "firebase_config";
import { SetReload } from "store/actions";
import { StaticHeaderComponent } from "components";
import { convertToSnakeCase } from "helpers";
import { Tables } from "constants/db_tables";
import "./style.css";

const LoginComponent = ({ bindReload }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnSave, setBtnSave] = useState("Submit");
  const [error, setErrors] = useState("");
  const [eyeIcon, setEyeIcon] = useState("fa-eye");
  const history = useHistory();

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleForm(event);
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();

    setBtnSave("Submitting...");
    const result = await signin({ email, password });
    setBtnSave("Submit");
    if (result.status !== 200) {
      setErrors(result.message);
      return;
    }
    bindReload(true);
    history.push("/app/assets");
  };

  const showPassword = () => {
    const input = document.getElementById("loginPass");
    if (input.type === "password") {
      setEyeIcon("fa-eye-slash");
      input.type = "text";
    } else {
      setEyeIcon("fa-eye");
      input.type = "password";
    }
  };

  const handleGoogleSignIn = async () => {
    setBtnSave("Submitting...");
    const res = await googleSignin();
    setBtnSave("Submit");

    if (res?.data?.additionalUserInfo?.isNewUser) {
      const userData = {
        email: res.data.email,
        username: convertToSnakeCase(res.data.displayName),
        displayName: res.data.displayName,
        permissions: {
          tnc: true,
          recordPageVisits: true,
          locationAccess: true,
          emailUpdates: true,
        },
        photoURL: null,
        depositAmount: 0,
        withdrawAmount: 0,
      };
      const createDbUser = await add(Tables.USERS, userData);
      if (createDbUser.status !== 200) {
        setErrors(createDbUser.message);
        return null;
      }
    } else if (res.status !== 200) {
      setErrors(res.message);
      return;
    }
    history.push("/app/assets");
  };

  return (
    <div className="login-page" onKeyDown={(e) => handleKeyDown(e)} id="login">
      <StaticHeaderComponent />
      <div className="container login-form">
        <div className="d-flex justify-content-center mt-5 pt-5">
          <div className="col-12 order-1 order-sm-2 col-sm-6 right-block">
            <div className="header text-center">
              <img
                src={require("assets/svgs/login/right_header.svg").default}
                className="header"
                alt="Header"
              />
            </div>
            <div className="body">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <div>
                      <img
                        className="mail-icon"
                        src={
                          require("assets/svgs/login/right_mail_icon.svg")
                            .default
                        }
                        alt="Header"
                      />
                    </div>
                  </span>
                </div>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  autoFocus={true}
                  aria-label="email"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon2">
                    <div>
                      <img
                        src={
                          require("assets/svgs/login/right_lock_icon.svg")
                            .default
                        }
                        alt="Header"
                      />
                    </div>
                  </span>
                </div>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  value={password}
                  type="password"
                  className="form-control"
                  id="loginPass"
                  placeholder="Password"
                  aria-label="password"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text append"
                    id="basic-addon3"
                    onClick={(e) => showPassword()}
                  >
                    {eyeIcon === "fa-eye" ? (
                      <img
                        className="show-password"
                        src={require("assets/svgs/Eye.svg").default}
                        alt="Eye"
                      />
                    ) : (
                      <img
                        className="show-password"
                        src={require("assets/svgs/EyeOpen.svg").default}
                        alt="EyeOpen"
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="text-center pt-2">
                <div className="ml-auto">
                  <Link to="/forgot_password" className="forgot-pw">
                    Forgot password
                  </Link>
                </div>
              </div>
              <div>
                <button
                  className="btn login-btn col-12"
                  disabled={btnSave !== "Submit"}
                  onClick={(e) => handleForm(e)}
                >
                  Login
                </button>
                <br />
                <Link to="/signup" className="signup-btn">
                  Sign up
                </Link>
                <br />
                <div className="mt-4 mb-2 separator">OR</div>
                <button
                  className="btn google-login-btn col-12 align-items-center"
                  onClick={handleGoogleSignIn}
                >
                  <i className="fa fa-google"></i> &nbsp;{" "}
                  <span className="">Login with Google</span>
                </button>
              </div>
              {error && (
                <div className="text-danger text-center mt-3">{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    bindReload: (content) => dispatch(SetReload(content)),
  };
};

export default connect(null, mapDispatchToProps)(LoginComponent);
