import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./style.css";

import { update } from "firebase_config";
import { Tables } from "constants/db_tables";
import JoinBetComponent from "../join_bet/component";
import StatsComponent from "../stats/component";

const TimerComponent = ({
  currentUser,
  bet,
  currentTime,
  duration,
  totalUserBets,
  userBet,
  saveUserBet,
  loading,
  setLoading,
  claimAmount,
}) => {
  const [gameStarted, setGameStarted] = useState(false);
  const [countDown, setCountDown] = useState(+bet.timer);
  const [questionIdx, setQuestionIdx] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(
    bet.timerQuestions[0]
  );
  const [selectedOption, setSelectedOption] = useState();
  const [gameCompleted, setGameCompleted] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);

  const startGame = async (options) => {
    await saveUserBet(options);
    setGameStarted(true);
  };

  useEffect(() => {
    gameStarted &&
      countDown > 0 &&
      setTimeout(() => setCountDown(countDown - 1), 1000);
  }, [countDown, gameStarted]);

  const setNextQuestion = () => {
    selectedQuestion.selectedOption = selectedOption;
    setUserAnswers([...userAnswers, selectedQuestion]);
    if (bet.timerQuestions.length > questionIdx + 1) {
      setSelectedOption("");
      setQuestionIdx(questionIdx + 1);
      setSelectedQuestion(bet.timerQuestions[questionIdx + 1]);
    } else {
      setGameCompleted(true);
    }
  };

  const cancelGame = () => {
    if (window.confirm("Your game is in progress. Are you sure to go back?")) {
      saveUserAnswers();
    }
  };

  const saveUserAnswers = async () => {
    setLoading(true);
    const res = await update(Tables.USER_BETS, userBet.id, {
      answers: userAnswers,
      correctAnswers: userAnswers.filter(
        (ua) => ua.selectedOption === ua.correctOption
      ).length,
    });
    if (res.status === 200) {
      toast.success("Your answers submitted.");
      setGameStarted(false);
    } else {
      toast.error("Something went wrong. Submit again!");
    }
    setLoading(false);
  };

  const TextComponent = () => (
    <>
      <div>
        <div className="my-2">{bet.description}</div>
        <div className="font-small">
          &#x1F558;
          {currentTime <= bet.expiresAt ? (
            <span>
              <span className="pl-2 pr-1">Game expires in</span>
              {duration?.hours()} hrs {duration?.minutes()} mins
            </span>
          ) : (
            <span className="pl-2 pr-1">Game expired</span>
          )}
        </div>
      </div>
      {bet.type === "image" && bet.contentLink && (
        <img src={bet.contentLink} width="100%" height="100%" />
      )}
      <JoinBetComponent
        bet={bet}
        currentUser={currentUser}
        userBet={userBet}
        saveUserBet={saveUserBet}
        loading={loading}
        setLoading={setLoading}
        claimAmount={claimAmount}
        startGame={startGame}
      />
      <StatsComponent
        bet={bet}
        currentUser={currentUser}
        totalUserBets={totalUserBets}
      />
    </>
  );

  const GameComponent = () => (
    <>
      {countDown <= 0 || gameCompleted ? (
        <div className="d-flex flex-inline justify-content-between align-items-center">
          <h5>{gameCompleted ? "Game completed" : "Time is up"}</h5>
          <div
            className="btn btn-sm fs-12 btn-violet-rounded px-3"
            onClick={saveUserAnswers}
          >
            Submit Answers
          </div>
        </div>
      ) : (
        <div className="">
          <div className="text-violet pull-right">{countDown} secs left</div>
          <div className="clearfix"></div>
          <div>
            <div className="my-2">{selectedQuestion.description}</div>
            {selectedQuestion.type === "image" &&
              selectedQuestion.contentLink && (
                <img
                  src={selectedQuestion.contentLink}
                  width="100%"
                  height="100%"
                />
            )}
            <div className="my-2">
              {selectedQuestion.options.map((option, idx) => (
                <div className="form-check pl-0 my-1" key={idx}>
                  <input
                    className="form-check-input"
                    type="radio"
                    id={`${idx}-${option}`}
                    name={idx}
                    value={option}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    checked={option === selectedOption}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${idx}-${option}`}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div
            className="btn btn-sm fs-12 btn-violet-rounded px-4"
            onClick={setNextQuestion}
          >
            Next
          </div>
          <div
            className="btn btn-sm fs-12 btn-danger-outline-r px-3 ml-2"
            onClick={cancelGame}
          >
            Cancel
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      {gameStarted && <GameComponent />}
      {!gameStarted && <TextComponent />}
    </>
  );
};

export default TimerComponent;
