import React from "react";
import "./style.css";

import JoinBetComponent from "../join_bet/component";
import StatsComponent from "../stats/component";

const PuzzleComponent = ({
  currentUser,
  bet,
  currentTime,
  duration,
  totalUserBets,
  selectedOption,
  userBet,
  saveUserBet,
  loading,
  setLoading,
  claimAmount,
  copyToClipboard,
  handleChange,
}) => {
  return (
    <>
      <div className="puzzle-description">
        <div className="my-2">{bet.description}</div>
        <div className="font-small mb-3">
          &#x1F558;
          {currentTime <= bet.expiresAt ? (
            <span>
              <span className="pl-2 pr-1">Game expires in</span>
              {duration?.hours()} hrs {duration?.minutes()} mins
            </span>
          ) : (
            <span className="pl-2 pr-1">Game expired</span>
          )}
        </div>
        {bet.type === "image" && bet.contentLink && (
          <img src={bet.contentLink} width="100%" height="100%" />
        )}
      </div>
      <div className="my-2">
        {bet.options.map((option, idx) => (
          <div
            className="form-check pl-0 my-1"
            key={idx}
            onClick={(e) => copyToClipboard(option)}
          >
            <input
              className="form-check-input"
              type="radio"
              id={`${bet.id}-${option}`}
              name={bet.id}
              value={option}
              onChange={(e) => handleChange(e.target.value, idx)}
              checked={option === selectedOption}
              disabled={userBet && userBet.postId === bet.id}
            />
            <label className="form-check-label" htmlFor={`${bet.id}-${option}`}>
              {option}
            </label>
          </div>
        ))}
      </div>
      <JoinBetComponent
        selectedOption={selectedOption}
        bet={bet}
        currentUser={currentUser}
        userBet={userBet}
        saveUserBet={saveUserBet}
        loading={loading}
        setLoading={setLoading}
        claimAmount={claimAmount}
      />
      <StatsComponent
        bet={bet}
        currentUser={currentUser}
        totalUserBets={totalUserBets}
      />
    </>
  );
};

export default PuzzleComponent;
