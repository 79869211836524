import React, { useEffect } from "react";
import "./style.css";

import { StaticHeaderComponent } from "components";
import { FAQS } from "constants/faq";

const FaqComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StaticHeaderComponent />
      <div className="dashboard-content faq-wrapper">
        <h3 className="fw-300 text-center pt-5">FAQ</h3>
        {FAQS.map((obj, objIdx) => (
          <div className="container" key={`${obj.key}_${objIdx}`}>
            <div className="col-12">
              <div className="">
                {obj.values[0] &&
                  obj.values.map((que, queIdx) => (
                    <div key={queIdx}>
                      <input
                        type="checkbox"
                        id={`question_${objIdx}_${queIdx}`}
                        name="q"
                        className="questions"
                      />
                      <div>
                        <label
                          htmlFor={`question_${objIdx}_${queIdx}`}
                          className="d-flex flex-row align-items-center justify-content-between question"
                        >
                          <div>{que.question}</div>
                          <div className="plus">+</div>
                        </label>
                      </div>
                      <div
                        className="answers"
                        dangerouslySetInnerHTML={{ __html: que.answer }}
                      ></div>
                    </div>
                  ))}
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
    </>
  );
};

export default FaqComponent;
