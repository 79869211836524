import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { StaticHeaderComponent } from "components";
import SectionOneComponent from "./section_one/component";
import SectionTwoComponent from "./section_two/component";
import SectionFourComponent from "./section_four/component";
import "./style.css";

const HomeComponent = () => {
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  return (
    <div className="home-wrapper">
      <StaticHeaderComponent />
      <SectionOneComponent smallDevice={smallDevice} />
      <SectionTwoComponent smallDevice={smallDevice} />
      <div className="container h5 fw-300 d-none">
        <h4 className="fw-400 pt-5 pb-3">Is this you?</h4>
        <ul>
          <li className="py-2">
            Addicted to social media - spends 3+ hours online each day.
          </li>
          <li className="py-2">
            Works damn hard to make money - but not sufficient.
          </li>
        </ul>
        <p className="text-center py-5">Then this platform is for gamers..</p>
        <div className="container">
          <div className="d-flex flex-column flex-md-row justify-content-center h4 py-3 fw-300">
            <span className="text-violet pr-2">Step 1:</span>{" "}
            <span className="pt-2 p-md-0">Watch the content</span>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center h4 py-3 fw-300">
            <span className="text-violet pr-2">Step 2:</span>{" "}
            <span className="pt-2 p-md-0">Join a game</span>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center h4 py-3 fw-300">
            <span className="text-violet pr-2">Step 3:</span>{" "}
            <span className="pt-2 p-md-0">Earn the pot money</span>
          </div>
        </div>
      </div>
      <SectionFourComponent smallDevice={smallDevice} />
      <div className="footer">
        <div className="container text-white">
          <div className="d-flex flex-column flex-sm-row font-small py-4">
            <span className="mr-auto">&copy; SEXTANS LTD &middot; 2022</span>
            <Link to="/tnc" className="text-white px-2">
              Terms & Conditions
            </Link>
            <Link to="/privacy_policy" className="text-white px-2">
              Privacy Policy
            </Link>
            <Link to="/faq" className="text-white px-2">
              FAQ
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
