import React from "react";
import { connect } from "react-redux";
import "./style.css";

import { ENV } from "constants/env_keys";
import { SetDbUser } from "store/actions";

const JoinBetComponent = ({
  currentUser,
  bet,
  selectedOption,
  userBet,
  saveUserBet,
  loading,
  setLoading,
  bindDbUser,
  claimAmount,
  startGame,
}) => {
  const currentTime = new Date().getTime();

  const getMessage = () => {
    let message = `You joined the game. Winners will be announced once the game ends.`;

    if (
      currentTime > bet.expiresAt &&
      !Object.hasOwn(userBet, "winningAmount")
    ) {
      message = `You joined the game. Winners will be announced soon.`;
    }

    if (
      currentTime > bet.expiresAt &&
      Object.hasOwn(userBet, "winningAmount") &&
      userBet.winningAmount === 0
    ) {
      message = "Oops! you lost in this game. Try another";
    }

    if (
      currentTime > bet.expiresAt &&
      Object.hasOwn(userBet, "winningAmount") &&
      userBet.winningAmount > 0 &&
      !userBet.claimed
    ) {
      message = `Your winning amount: ${ENV.CURRENCY}${userBet.winningAmount}/-`;
    }

    if (
      currentTime > bet.expiresAt &&
      Object.hasOwn(userBet, "winningAmount") &&
      userBet.winningAmount > 0 &&
      userBet.claimed
    ) {
      message = `You claimed the winning amount of ${ENV.CURRENCY}${userBet.winningAmount}/-`;
    }
    return message;
  };

  return (
    <div className="mt-3">
      {userBet ? (
        <div className="alert alert-info font-small text-center" role="alert">
          <span dangerouslySetInnerHTML={{ __html: getMessage() }}></span>
          {userBet.winningAmount > 0 && !userBet.claimed && (
            <b
              className="text-info font-small pointer"
              onClick={(e) => claimAmount()}
            >
              {loading ? (
                <span className="pl-2 spinner-border spinner-border-sm"></span>
              ) : (
                " Claim Now"
              )}
            </b>
          )}
        </div>
      ) : (
        <div className="text-center">
          {bet.category !== "timer" && bet.expiresAt >= currentTime && (
            <div>
              <button
                className="btn btn-sm btn-violet-rounded px-3"
                onClick={(e) => saveUserBet({ betAmount: bet.amount })}
                disabled={!selectedOption}
              >
                {loading ? (
                  <span className="pl-2 spinner-border spinner-border-sm"></span>
                ) : (
                  "Join Game"
                )}
              </button>
            </div>
          )}
          {bet.category === "timer" && bet.expiresAt >= currentTime && (
            <div>
              <button
                className="btn btn-sm btn-violet-rounded px-3 mb-3"
                onClick={(e) => startGame({ betAmount: bet.amount })}
              >
                {loading ? (
                  <span className="pl-2 spinner-border spinner-border-sm"></span>
                ) : (
                  "Start Game"
                )}
              </button>
            </div>
          )}
        </div>
      )}
      <button className="btn btn-sm btn-violet-outline d-none">
        Decide Winners
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    bindDbUser: (content) => dispatch(SetDbUser(content)),
  };
};

export default connect(null, mapDispatchToProps)(JoinBetComponent);
