import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import "./style.css";

import HeaderComponent from "./header";

import {
  add,
  timestamp,
  uploadFile,
  removeFile,
  batchWrite,
} from "firebase_config";
import { post } from "services";
import { Tables } from "constants/db_tables";
import { SetNewPost } from "store/actions";
import PuzzleComponent from "./puzzle/component";
import YoutubeComponent from "./youtube/component";
import TimerComponent from "./timer/component";

const ParentComponent = (props) => {
  const { currentUser } = props;
  const [loading, setLoading] = useState(false);
  const [betDescription, setBetDescription] = useState();
  const [betOptions, setBetOptions] = useState();
  const [betContentLink, setBetContentLink] = useState();
  const [betAmount, setBetAmount] = useState(0);
  const [betPotAmount, setBetPotAmount] = useState(0);
  const [betTimer, setBetTimer] = useState(300); // in secs
  const [betCategory, setBetCategory] = useState("timer");
  const [betType, setBetType] = useState("video");

  const [timerQuestions, setTimerQuestions] = useState([]);

  const sendAlertsToFollowers = async (res = {}, post) => {
    if (res.path && !post.anonymous) {
      /* Send alerts to all followers */
      const relationsIds = _.without(
        _.map(props.relations, (rln) => {
          if (rln.userIdTwo === currentUser.id && rln.status === 1) {
            return rln.userIdOne;
          }
        }),
        undefined
      );

      const logText = `@${currentUser.username} recently posted a game`;
      await batchWrite("logs", relationsIds, {
        text: logText,
        photoURL: currentUser.photoURL,
        userId: currentUser.id,
        actionLink: `/app/assets/${res.path.replace("posts/", "")}`,
        unread: true,
        timestamp,
      });
    }
  };

  const saveNewPost = async (opts) => {
    if (betCategory !== "timer" && !betDescription) {
      toast.error("Bet description is mandatory");
      return null;
    }
    if (!betContentLink && betCategory === "youtube") {
      toast.error("Game content link is mandatory");
      return null;
    }
    if (!betAmount) {
      toast.error("Game amount is mandatory");
      return null;
    }
    if (betCategory !== "timer" && !betOptions) {
      toast.error("Game options are mandatory");
      return null;
    }
    if (betOptions && betOptions.split(";").length < 2) {
      toast.error("Minimum 2 options required to create a game");
      return null;
    }
    if (betAmount === 0 && !betPotAmount) {
      toast.error("Pot amount is required for free games");
      return null;
    }
    if (betCategory === "timer" && !betTimer) {
      toast.error("Timer is mandatory");
      return null;
    }
    setLoading(true);

    let result = "";
    let postData = {};
    const expiresAt = new Date().getTime() + 12 * 60 * 60 * 1000; // Bet Ending time: 12 hrs
    const predictsAt = new Date().getTime() + 24 * 60 * 60 * 1000; // Prediction time: 24 hrs

    if (
      window.confirm(
        "A bet cannot be changed or deleted once it has been posted. Are you sure to create this bet?"
      )
    ) {
      if (betCategory === "timer") {
        postData = Object.assign(postData, {
          active: true,
          description: betDescription.trim(),
          options: [],
          contentLink: "",
          amount: Number(betAmount?.trim() || 0),
          potAmount: Number(betPotAmount?.trim() || 0),
          userId: currentUser.id,
          expiresAt,
          predictsAt: expiresAt,
          category: betCategory,
          timer: betTimer,
          timestamp,
        });
        postData.timerQuestions = timerQuestions.map((tq) => ({
          correctOption: tq.correctOption.trim(),
          description: tq.description.trim(),
          options: tq.options.split(";").map((opt) => opt.trim()),
          contentLink: tq.contentLink?.trim() || "",
          type: tq.contentLink ? "image" : "",
        }));
      } else {
        postData = Object.assign(postData, {
          active: true,
          description: betDescription.trim(),
          options: betOptions.split(";").map((opt) => opt.trim()),
          contentLink: betContentLink?.trim() || "",
          amount: Number(betAmount?.trim() || 0),
          potAmount: Number(betPotAmount?.trim() || 0),
          userId: currentUser.id,
          expiresAt,
          predictsAt: betCategory === "youtube" ? predictsAt : expiresAt,
          category: betCategory,
          type: betType,
          timestamp,
        });
      }
      result = await add(Tables.POSTS, postData);
      if (betCategory === "youtube") {
        await post("/create_post_task", {
          id: result.data.id,
          path: result.data.path,
          predictsAt: predictsAt / 1000,
        });
      }
      // When a new post added, alert all followers
      await sendAlertsToFollowers(result.data, postData);
    }
    /* Log the activity */
    await add(Tables.LOGS, {
      text: `${currentUser.displayName} created a bet`,
      photoURL: currentUser.photoURL,
      receiverId: "",
      userId: currentUser.id,
      actionType: "create",
      collection: "posts",
      timestamp,
    });

    setLoading(false);
    if (result.status === 200) {
      props.history.push({
        pathname: "/app/assets",
        state: { postingSuccess: true, reloadPosts: true },
      });
      toast.success("Your bet is now live");
    } else {
      toast.error("Something went wrong. Try later!");
    }
  };

  const uploadImage = async (file) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setLoading(true);
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      setBetContentLink(url);
      setBetType("image");
      setLoading(false);
    });
  };

  const deleteImage = async (url) => {
    if (window.confirm("Are you sure to remove image?")) {
      await removeFile(url);
      setBetContentLink((prevState) => {
        return "";
      });
    }
  };

  return (
    <div>
      <HeaderComponent
        save={saveNewPost}
        loading={loading}
        saveBtn="Share"
        headerText="Create Game"
      />
      <div>
        <div className="dashboard-content">
          <div className="container create-post-wrapper pt-5 mt-sm-5">
            <div className="card p-2 p-sm-4 my-3">
              <div>
                <label htmlFor="form-question">Game category:</label>
                <select
                  className="form-control font-small"
                  aria-label=".form-select-sm"
                  onChange={(e) => setBetCategory(e.target.value)}
                >
                  <option value="timer" defaultValue>
                    Timer
                  </option>
                  <option value="puzzle">Puzzle</option>
                  <option value="youtube">Youtube</option>
                </select>
              </div>
              <div className="mt-4">
                <label htmlFor="form-question">Game fee:</label>
                <input
                  type="number"
                  className="form-control"
                  name="form-question"
                  placeholder="in GBP"
                  onChange={(e) => setBetAmount(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label htmlFor="form-question">Pot amount:</label>
                <input
                  type="number"
                  className="form-control"
                  name="form-question"
                  placeholder="in GBP"
                  onChange={(e) => setBetPotAmount(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label htmlFor="form-question">Game description:</label>
                <input
                  type="text"
                  className="form-control"
                  name="form-question"
                  placeholder="Ex: How many views does this video get?"
                  onChange={(e) => setBetDescription(e.target.value)}
                />
              </div>
              {betCategory === "timer" && (
                <>
                  <div className="mt-4">
                    <label htmlFor="timer">Timer:</label>
                    <input
                      type="number"
                      className="form-control"
                      name="timer"
                      placeholder="in seconds"
                      onChange={(e) => setBetTimer(e.target.value)}
                    />
                  </div>
                  <hr className="my-4" />
                  <TimerComponent
                    currentUser={currentUser}
                    uploadImage={uploadImage}
                    deleteImage={deleteImage}
                    betContentLink={betContentLink}
                    timerQuestions={timerQuestions}
                    setTimerQuestions={setTimerQuestions}
                  />
                </>
              )}
              {betCategory === "puzzle" && (
                <PuzzleComponent
                  currentUser={currentUser}
                  uploadImage={uploadImage}
                  deleteImage={deleteImage}
                  betContentLink={betContentLink}
                  setBetDescription={setBetDescription}
                  setBetOptions={setBetOptions}
                />
              )}
              {betCategory === "youtube" && (
                <YoutubeComponent
                  currentUser={currentUser}
                  betContentLink={betContentLink}
                  setBetDescription={setBetDescription}
                  setBetOptions={setBetOptions}
                  setBetContentLink={setBetContentLink}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { relations } = state.relationships;
  const { wallet } = state.wallet;
  const { prices } = state.prices;
  return { relations, wallet, prices };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bindNewPost: (content) => dispatch(SetNewPost(content)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ParentComponent));

/*
const uploadAudio = async (file) => {
  if (!file) {
    setResult({
      status: 400,
      message: "A new audio required",
    });
    return null;
  }
  setBtnUpload("uploading");
  await uploadFile(file, "audio", (url) => {
    setFileUrl(url);
    setBtnUpload("upload");
  });
};
const deleteFile = async () => {
  if (window.confirm("Are you sure to remove audio?")) {
    await removeFile(fileUrl);
    setFileUrl((prevState) => {
      return "";
    });
  }
};
*/
