import React from "react";

const TimerComponent = ({
  currentUser,
  uploadImage,
  deleteImage,
  betContentLink,
  timerQuestions,
  setTimerQuestions,
}) => {
  const handleChange = async (idx, key, value) => {
    if (key === "uploadImage") {
      await uploadImage(value);
      timerQuestions[idx].contentLink = betContentLink;
    } else if (key === "deleteImage") {
      await deleteImage(value);
      timerQuestions[idx].contentLink = "";
    } else {
      timerQuestions[idx][key] = value;
    }
    setTimerQuestions([...timerQuestions]);
  };

  const addTimerQuestion = () => {
    setTimerQuestions([
      ...timerQuestions,
      {
        description: "",
        contentLink: "",
        options: [],
      },
    ]);
  };

  const deleteTimerQuestion = (idx) => {
    timerQuestions.splice(idx, 1);
    setTimerQuestions([...timerQuestions]);
  };

  return (
    <>
      {timerQuestions.map((que, idx) => (
        <div key={idx}>
          <div className="d-flex flex-inline justify-content-between align-items-center mb-3">
            <div className="fw-700">Question {idx + 1}:</div>
            <div
              className="btn btn-sm btn-danger text-white"
              onClick={(e) => deleteTimerQuestion(idx)}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
          <div>
            <label htmlFor="form-question">Description:</label>
            <input
              type="text"
              className="form-control"
              name="form-question"
              placeholder="Ex: How many views does this video get?"
              value={timerQuestions[idx].description}
              onChange={(e) => handleChange(idx, "description", e.target.value)}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="options">Game options:</label>
            <em className="font-small float-right">Must be ; separated</em>
            <input
              type="text"
              className="form-control"
              name="options"
              placeholder="Ex: 250,000 ; 300,000 ; None"
              value={timerQuestions[idx].options}
              onChange={(e) => handleChange(idx, "options", e.target.value)}
            />
          </div>
          <div
            className={`mt-4 ${
              timerQuestions[idx].options ? "d-block" : "d-none"
            }`}
          >
            <label htmlFor="options">Correct option:</label>
            <em className="font-small float-right">
              Make sure options must be matched
            </em>
            <input
              type="text"
              className="form-control"
              name="options"
              placeholder="Ex: 250,000"
              value={timerQuestions[idx].correctOption}
              onChange={(e) =>
                handleChange(idx, "correctOption", e.target.value)
              }
            />
          </div>
          <div className="mt-4">
            <label htmlFor="form-question">Content link:</label>
            <input
              type="text"
              className="form-control"
              name="form-question"
              placeholder="Video link Ex: https://www.youtube.com/watch?v=jHAucy4N7rY"
              value={timerQuestions[idx].contentLink}
              onChange={(e) => handleChange(idx, "contentLink", e.target.value)}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="staticImage">Image: &nbsp; +</label>
            <input
              type="file"
              className="form-control-plaintext d-none"
              id="staticImage"
              onChange={(e) =>
                handleChange(idx, "uploadImage", e.target.files[0])
              }
              accept="image/*"
            />
            {timerQuestions[idx].contentLink && (
              <div>
                <button
                  className="btn btn-violet"
                  onClick={(e) =>
                    handleChange(
                      idx,
                      "deleteImage",
                      timerQuestions[idx].contentLink
                    )
                  }
                >
                  Delete Image
                </button>
                <img
                  src={timerQuestions[idx].contentLink}
                  className="camera-icon"
                  alt="Image"
                  width="150px"
                  height="150px"
                />
              </div>
            )}
          </div>
        </div>
      ))}
      <button
        className="btn btn-sm btn-violet col-12 ml-auto py-2 pointer"
        onClick={addTimerQuestion}
      >
        Add Question
      </button>
    </>
  );
};

export default TimerComponent;
