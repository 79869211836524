import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";

import { Tables } from "constants/db_tables";
import { ENV } from "constants/env_keys";
import { getQuery, firestore, add, getId, update } from "firebase_config";
import { post } from "services";
import DecideWinnersComponent from "../decide_winners/component";
import YoutubeComponent from "../youtube/component";
import PuzzleComponent from "../puzzle/component";
import TimerComponent from "../timer/component";
import { SetDbUser } from "store/actions";

const NewPostComponent = ({ currentUser, post: POST, bindDbUser, myBets }) => {
  const [bet, setBet] = useState(POST);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [userBet, setUserBet] = useState();
  const [totalUserBets, setTotalUserBets] = useState();
  const [duration, setDuration] = useState();
  const [correctOption, setCorrectOption] = useState();
  const history = useHistory();
  const currentTime = new Date().getTime();

  useEffect(() => {
    const getUserBet = async () => {
      const userBets = await getQuery(
        firestore
          .collection(Tables.USER_BETS)
          .where("postId", "==", bet.id)
          .get()
      );
      const currentUserBet = userBets.filter(
        (ub) => ub.userId === currentUser.id
      );
      if (currentUserBet.length > 0) {
        setUserBet(currentUserBet[0]);
        setSelectedOption(currentUserBet[0].selectedOption);
      }
      if (userBets.length > 0) {
        setTotalUserBets(userBets);
      }
    };

    if (!userBet) {
      getUserBet();
    }
  }, []);

  useEffect(() => {
    function calcDuration() {
      setInterval(() => {
        const duration = moment.duration(
          bet.expiresAt - new Date().getTime(),
          "milliseconds"
        );
        setDuration(duration);
      }, 1000); // refresh for every 1min
    }

    if (!duration && bet.expiresAt > currentTime) {
      calcDuration();
    }
  }, [duration, setDuration]);

  const copyToClipboard = (option) => {
    navigator.clipboard.writeText(option);
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const saveUserBet = async (options) => {
    const { betAmount } = options;
    if (currentUser.depositAmount < betAmount) {
      history.push({
        pathname: "/app/recharge",
        state: { betAmount },
      });
      return;
    }

    setLoading(true);
    /* update user amounts */
    const depositAmount = Number(currentUser.depositAmount) - Number(betAmount);
    await update(Tables.USERS, currentUser.id, {
      depositAmount,
    });
    await bindDbUser({ ...currentUser, depositAmount });

    const txn = await add(Tables.TRANSACTIONS, {
      type: "debit",
      userId: currentUser.id,
      amount: betAmount,
      currency: "gbp",
      currencySymbol: ENV.CURRENCY,
      postId: bet.id,
      status: "success",
    });

    const params = {
      postId: bet.id,
      transactionId: txn.data.id,
      userId: currentUser.id,
      amount: betAmount,
      selectedOption,
    };

    const res = await add(Tables.USER_BETS, params);
    if (res.status === 200) {
      const ub = await getId(Tables.USER_BETS, res.data.id);
      ub.id = res.data.id;
      setUserBet(ub);
    }
    setLoading(false);
  };

  const claimAmount = async () => {
    setLoading(true);
    if (!userBet.claimed) {
      await update(Tables.USER_BETS, userBet.id, {
        claimed: true,
      });
      const withdrawAmount =
        Number(currentUser.withdrawAmount) + Number(userBet.winningAmount);
      await update(Tables.USERS, currentUser.id, {
        withdrawAmount,
      });

      toast.success("Your winning amount is deposited to wallet");
      setUserBet((prevState) => ({ ...prevState, claimed: true }));
      await bindDbUser({ ...currentUser, withdrawAmount });
    }
    setLoading(false);
  };

  const saveCorrectOption = async () => {
    if (
      confirm(
        `Your selected option: ${correctOption}. Are you sure to confirm?`
      )
    ) {
      setLoading(true);
      const res = await post("/decide_winners", {
        postId: bet.id,
        correctOption,
      });
      setLoading(false);
      if (res.status === 201) {
        setBet((prevState) => ({ ...prevState, correctOption }));
      }
    }
  };

  const displayCard = () => {
    return (
      <div className="card p-3 my-3">
        {bet.category === "puzzle" && (
          <PuzzleComponent
            currentUser={currentUser}
            bet={bet}
            currentTime={currentTime}
            duration={duration}
            totalUserBets={totalUserBets}
            selectedOption={selectedOption}
            userBet={userBet}
            saveUserBet={saveUserBet}
            loading={loading}
            setLoading={setLoading}
            claimAmount={claimAmount}
            copyToClipboard={copyToClipboard}
            handleChange={handleChange}
          />
        )}
        {bet.category === "youtube" && (
          <YoutubeComponent
            currentUser={currentUser}
            bet={bet}
            currentTime={currentTime}
            duration={duration}
            totalUserBets={totalUserBets}
            selectedOption={selectedOption}
            userBet={userBet}
            saveUserBet={saveUserBet}
            loading={loading}
            setLoading={setLoading}
            claimAmount={claimAmount}
            copyToClipboard={copyToClipboard}
            handleChange={handleChange}
          />
        )}
        {bet.category === "timer" && (
          <TimerComponent
            currentUser={currentUser}
            bet={bet}
            currentTime={currentTime}
            duration={duration}
            totalUserBets={totalUserBets}
            selectedOption={selectedOption}
            userBet={userBet}
            saveUserBet={saveUserBet}
            loading={loading}
            setLoading={setLoading}
            claimAmount={claimAmount}
          />
        )}
        {currentUser.admin &&
          bet.predictsAt < currentTime &&
          !bet.correctOption && (
            <DecideWinnersComponent
              bet={bet}
              currentUser={currentUser}
              userBet={userBet}
              correctOption={correctOption}
              setCorrectOption={setCorrectOption}
              saveCorrectOption={saveCorrectOption}
              loading={loading}
            />
        )}
      </div>
    );
  };

  return myBets
    ? userBet?.userId === currentUser.id
      ? displayCard()
      : ""
    : displayCard();
};

const mapDispatchToProps = (dispatch) => {
  return {
    bindDbUser: (content) => dispatch(SetDbUser(content)),
  };
};

export default connect(null, mapDispatchToProps)(NewPostComponent);
