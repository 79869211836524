import React from "react";

const YoutubeComponent = ({
  currentUser,
  setBetOptions,
  betContentLink,
  setBetContentLink,
}) => {
  return (
    <>
      <div className="mt-4">
        <label htmlFor="form-question">Game options:</label>
        <em className="font-small float-right">Must be ; separated</em>
        <input
          type="text"
          className="form-control"
          name="form-question"
          placeholder="Ex: 250,000 ; 300,000 ; None"
          onChange={(e) => setBetOptions(e.target.value)}
        />
      </div>
      <div className="mt-4">
        <label htmlFor="form-question">Content link:</label>
        <input
          type="text"
          className="form-control"
          name="form-question"
          placeholder="Video link Ex: https://www.youtube.com/watch?v=jHAucy4N7rY"
          value={betContentLink}
          onChange={(e) => setBetContentLink(e.target.value)}
        />
      </div>
    </>
  );
};

export default YoutubeComponent;
