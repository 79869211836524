import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.css";

import NewPostComponent from "components/show_posts/children/new_post/component";
import { LoadingComponent } from "components";
import { getId } from "firebase_config";
import { gtokFavicon } from "images";

class ShowPostComponent extends Component {
  constructor(props) {
    super(props);
    this.propsState = props.history.location.state || {};
    this.state = {
      postId: props.computedMatch.params.post_id,
      post: "",
      loading: true,
      postUrl:
        process.env.REACT_APP_URL + "/" + props.computedMatch.params.post_id,
    };
  }

  componentDidMount() {
    this.loadPost();
  }

  loadPost = async () => {
    const id = this.props.computedMatch.params.post_id;
    const post = await getId("posts", id);
    post.id = id;
    this.setState({
      post,
      loading: false,
    });
  };

  redirectToHome = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div>
        <div>
          <div className="dashboard-content">
            <div className="feeling-wrapper">
              {this.state.post.status === 404 && (
                <div className="text-center mt-5 pt-5 pb-3 text-gray-6">
                  <i className="fa fa-trash fa-2x"></i>
                  <br />
                  <h5 className="pt-4">Oh no! Asset has been hidden.</h5>
                </div>
              )}
              <Helmet>
                <title>
                  {this.state.post && this.state.post.category.title}
                </title>
                <meta name="image" content={gtokFavicon} />
                <meta
                  name="description"
                  content={this.state.post && this.state.post.description}
                />
                <meta
                  name="keywords"
                  content={this.state.post && this.state.post.keywords}
                />
              </Helmet>
              {this.state.post && this.state.post.status !== 404 && (
                <div className="pt-5">
                  <NewPostComponent
                    currentUser={this.props.currentUser}
                    post={this.state.post}
                    myBets={this.state.myBets}
                  />
                </div>
              )}
            </div>
            {this.state.loading && <LoadingComponent />}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ShowPostComponent);
