export const FAQS = [
  {
    key: "General",
    values: [
      {
        question: "What is PayGames?",
        answer:
          "It is a game website that offers the chance to make real money. In short, a player should answer questions, take quizzes, and solve riddles. All players that participate in the game and submit correct answers to the questions will share the pot money equally.",
      },
      {
        question: "How PayGames helps me to earn money?",
        answer:
          "It is easy. Join the game and provide the correct answer for the question. If you answered correctly, you and the remaining winners will split the pot money equally.",
      },
      {
        question: "What is pot?",
        answer:
          "A pot is a place where all gaming fees are collected. Each game has one pot. Once the game ends, the winning players split the pot equally.",
      },
      {
        question: "What is fee?",
        answer:
          "A player must pay an entry fee to take part in the game. Entry fees vary depending on the game. The entire entry fee is put into a pot.",
      },
      {
        question: "What is the formula for pot?",
        answer: "Pot = Number of players * Fee",
      },
      {
        question: "When will the game winners be revealed?",
        answer:
          "It depends on each game. If it's a puzzle, the winners will be announced immediately after the game. However, the winners of a social media game will be revealed in accordance with the question.",
      },
      {
        question: "Can I deposit money in other curriencies?",
        answer:
          "No. PayGames only operates in the UK and only accepts GBP (£).",
      },
      {
        question: "Is there any minimum amount to deposit?",
        answer: "Yes, £20.00 is the minimum deposit amount.",
      },
      {
        question: "Shall I withdraw at any time?",
        answer:
          "Yes, you have unlimited access to your withdrawable funds. The minimum amount of cash you can withdraw is £20.",
      },
      {
        question: "Can I withdraw my deposit?",
        answer: "No. Your money that has been deposited cannot be withdrawn.",
      },
      {
        question: "How long will it take after I withdraw?",
        answer:
          "Your money that is available for withdrawal often arrives in your bank account within 7 to 10 business days.",
      },
      {
        question: "Is it possible for me to join PayGames as a partner?",
        answer:
          'Yes. You can collaborate with us to create games. This feature will soon be available. Send us an email at if you would like more information at <a href="mailto:team@perlainfotech.com" class="text-violet">team@perlainfotech.com</a>',
      },
    ],
  },
];
