import React from "react";
import { Link } from "react-router-dom";

import { FAQS } from "constants/faq";

const SectionFourComponent = ({ smallDevice }) => {
  return (
    <div className={`${smallDevice ? "mb-5" : "dashboard-content"}`}>
      <h1 className="fw-400 text-center my-4 pt-5">FAQ</h1>{" "}
      <hr className="hr-small mb-4" />
      {FAQS.map((obj, objIdx) => (
        <div className="container fs-18" key={`${obj.key}_${objIdx}`}>
          {obj.values[0] &&
            obj.values.map(
              (que, queIdx) =>
                queIdx < 8 && (
                  <div key={queIdx}>
                    <input
                      type="checkbox"
                      id={`question_${objIdx}_${queIdx}`}
                      name="q"
                      className="questions"
                    />
                    <div>
                      <label
                        htmlFor={`question_${objIdx}_${queIdx}`}
                        className="d-flex flex-row align-items-center justify-content-between question"
                      >
                        <div>{que.question}</div>
                        <div className="plus">+</div>
                      </label>
                    </div>
                    <div
                      className="answers"
                      dangerouslySetInnerHTML={{ __html: que.answer }}
                    ></div>
                  </div>
                )
            )}
        </div>
      ))}
      <div className="text-center mt-4">
        <Link to="/faq" className="btn btn-violet-rounded px-4 py-2 fw-400">
          See more
        </Link>
      </div>
    </div>
  );
};

export default SectionFourComponent;
