import React from "react";
import "./style.css";

const SectionTwoComponent = ({ smallDevice }) => {
  return (
    <div className="section-two-wrapper">
      <div>
        <hr className={`${smallDevice ? "d-none" : "hr-small"}`} />
        <h1 className="text-center m-5">What we offer You</h1>
        <div className="text-center fs-18 mx-3">
          <div className="btn btn-violet-outline-r py-3 py-sm-2 col-sm-2 mx-sm-2 my-2">
            Free Games
          </div>
          <div className="btn btn-violet-outline-r py-3 py-sm-2 col-sm-2 mx-sm-2 my-2">
            Time Games
          </div>
          <div className="btn btn-violet-outline-r py-3 py-sm-2 col-sm-2 mx-sm-2 my-2">
            Fee Games
          </div>
        </div>
        <div className="row align-items-center my-5 py-sm-5">
          <div className="col-12 col-sm-6">
            <img
              src="https://storage.googleapis.com/lg-main.appspot.com/web/free2.gif"
              alt="play_image"
              className="col-12 col-sm-9 offset-sm-2 puzzle-gif"
            />
          </div>
          <div className="lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-4 ">
            <h2>Free Games</h2>
            Are you interested to play with ZERO fee? <br /> <br />
            Play <span className="fw-600">Free Games</span>. Simply join the
            game and earn money. We pay atleast{" "}
            <span className="text-violet">£100</span> a day for the winners.
          </div>
        </div>
        {smallDevice ? (
          <div className="row align-items-center my-5">
            <div className="col-10 offset-1">
              <img
                src="https://storage.googleapis.com/lg-main.appspot.com/web/timer1.gif"
                alt="play_image"
                className="col-12 col-md-9 puzzle-gif"
              />
            </div>
            <div className="lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-4 ">
              <h2>Time Games</h2>
              Are you QUICK in solving puzzles? <br /> <br />
              Join <span className="fw-600">Time Games</span>. Puzzles include
              Maths, Science, History, Crosswords, Movies & more. You win big
              with less investment.
            </div>
          </div>
        ) : (
          <div className="row align-items-center my-5 py-4">
            <div className="lh-32 col-12 col-sm-6 offset-sm-1">
              <h2>Time Games</h2>
              Are you QUICK in solving puzzles? <br /> <br />
              Join <span className="fw-600">Time Games</span>. Puzzles include
              Maths, Science, History, Crosswords, Movies & more. You win big
              with less investment.
            </div>
            <div className="col-12 col-sm-5">
              <img
                src="https://storage.googleapis.com/lg-main.appspot.com/web/timer1.gif"
                alt="play_image"
                className="col-12 col-md-9 puzzle-gif"
              />
            </div>
          </div>
        )}
        <div className="row align-items-center my-5 py-sm-5">
          <div className="col-12 col-sm-6">
            <img
              src="https://storage.googleapis.com/lg-main.appspot.com/web/fee1.gif"
              alt="play_image"
              className="col-12 col-sm-9 offset-sm-2 puzzle-gif"
            />
          </div>
          <div className="lh-32 col-12 col-sm-5 text-center text-sm-left p-5 p-sm-0 pl-sm-4 ">
            <h2>Fee Games</h2>
            Do you want to play SAFE and compete others? <br /> <br />
            Then <span className="fw-600">Fee Games</span> are for you. Find an
            answer & then Join the game.
          </div>
        </div>
      </div>
      <div className="bg-violet-400 text-center pb-5 p-5 my-5 text-white">
        <h1 className="text-center">Till now</h1>
        <hr className="hr-small hr-white mt-4 mb-sm-5" />
        <div className="d-flex flex-column flex-md-row justify-content-around">
          <div>
            <h4 className="pt-5 pt-md-0">100+</h4>
            <span className="lead">games</span>
          </div>
          <div>
            <h4 className="pt-5 pt-md-0">1000+ </h4>
            <span className="lead">players</span>
          </div>
          <div>
            <h4 className="pt-5 pt-md-0">£100,000+ </h4>
            <span className="lead">paid</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwoComponent;
