import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Metadata } from "constants/index";
import { gtokFavicon } from "images";
import "./style.css";

const StaticHeaderComponent = ({ routes }) => {
  const [metaDetails, setMetaDetails] = useState({});
  useEffect(() => {
    const path = window.location.pathname;
    Object.keys(Metadata).map((key) => {
      if (key.includes(path)) {
        setMetaDetails(Metadata[key]);
      }
      return key;
    });
  }, [metaDetails]);

  return (
    <div className="static-header">
      <Helmet>
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description} />
        <meta name="keywords" content={metaDetails.keywords} />
        <link rel="icon" type="image/png" href={gtokFavicon} sizes="16x16" />
      </Helmet>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="home-page-title">
              PayGames
            </Link>
          </div>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <div className="nav-link p-0">
                <Link to="/login" className="text-violet">
                  Login
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default StaticHeaderComponent;
