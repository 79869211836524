import React from "react";
import { Link } from "react-router-dom";

const SectionOneComponent = ({ smallDevice }) => {
  return (
    <div className="container d-flex flex-column flex-md-row align-items-center section-one-wrapper">
      <div
        className={`col-12 col-md-6 ${
          smallDevice && "pt-64 pb-48 text-center"
        }`}
      >
        <h1
          className={`fw-400 ${smallDevice ? "display-4 pb-6" : "display-3"}`}
        >
          Play Games
        </h1>
        <p className="lead fw-400">A gaming platform that pays real currency</p>
        <Link to="/login" className="btn btn-violet-rounded px-4 py-2 fw-400">
          Play now
        </Link>
      </div>
      <img
        src="https://storage.googleapis.com/lg-main.appspot.com/web/4.jpeg"
        alt="play_image"
        className="col-12 col-md-7 my-md-5"
      />
    </div>
  );
};

export default SectionOneComponent;
