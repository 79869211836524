export const Metadata = {
  "/app/home": {
    title: "PayGames",
    description:
      "A real gaming platform that pays unlimited cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "home",
    keywords: "pay games,games,money games,pot,players,puzzles,videos",
  },
  "/app/assets": {
    title: "PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "posts",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/profile": {
    title: "Profile | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "profile",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/search": {
    title: "Search | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "search",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/similarities": {
    title: "Similarities | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "similarities",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/graphs": {
    title: "Graphs | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "graphs",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/alerts": {
    title: "Alerts | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "alerts",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/chats": {
    title: "Chats | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "chats",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/chat_requests": {
    title: "Requests | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "chat_requests",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/payments": {
    title: "Payments | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "payments",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/support": {
    title: "Support | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "support",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/create_activity": {
    title: "Share activities | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "create_activity",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/challenges": {
    title: "Challenges | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "challenges",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/send_company_alerts": {
    title: "Send company alerts | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "send_company_alerts",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/app/create_asset": {
    title: "Create bet | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    path: "create_asset",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/login": {
    title: "Login | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/signup": {
    title: "Signup | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/signup/:username": {
    title: "Signup | PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
  },
  "/signup_success": {
    title: "Signup success | PayGames",
  },
  "/forgot_password": {
    title: "Forgot password | PayGames",
    description: "Forgot password in lets GTOK application.",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/logout": {
    title: "Logout | PayGames",
    description: "Logged out.",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/profile_deleted": {
    title: "Profile deleted | PayGames",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  "/error": {
    title: "Error | PayGames",
    keywords: "social bets,games,social media,bets,pot,players",
  },
  default: {
    title: "PayGames",
    description:
      "A real gaming platform to earn cash. Predictions, puzzles, and more. Watch, Play, and Earn.",
    keywords: "social bets,games,social media,bets,pot,players",
  },
};

export const ChartData = [
  {
    id: "Yours",
    color: "hsl(114, 70%, 50%)",
    data: [
      {
        x: "Food",
        y: 248,
      },
      {
        x: "Job",
        y: 194,
      },
      {
        x: "Study",
        y: 143,
      },
      {
        x: "Relationships",
        y: 83,
      },
      {
        x: "Attitude",
        y: 99,
      },
      {
        x: "Friends",
        y: 118,
      },
      {
        x: "Places",
        y: 289,
      },
      {
        x: "Adult(18+)",
        y: 291,
      },
      {
        x: "Dreams",
        y: 260,
      },
      {
        x: "Life",
        y: 239,
      },
      {
        x: "Memories",
        y: 283,
      },
      {
        x: "Sports",
        y: 222,
      },
      {
        x: "Others",
        y: 22,
      },
    ],
  },
];
