import React, { useState } from "react";
import ReactPlayer from "react-player";

import JoinBetComponent from "../join_bet/component";
import StatsComponent from "../stats/component";
import { post } from "services";

const YoutubeComponent = ({
  currentUser,
  bet,
  currentTime,
  duration,
  totalUserBets,
  selectedOption,
  userBet,
  saveUserBet,
  loading,
  setLoading,
  claimAmount,
  copyToClipboard,
  handleChange,
}) => {
  const [contentCount, setContentCount] = useState();

  const getCount = async () => {
    setLoading(true);
    const res = await post("/content_count", {
      postId: bet.id,
    });
    setContentCount(res.data.contentCount);
    setLoading(false);
  };

  return (
    <>
      <div className="puzzle-description">
        <div className="my-2">
          {bet.description.replace("?", "")} by{" "}
          <span className="text-violet">
            {new Date(bet.predictsAt || bet.expiresAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </span>
          ?
          <a
            className="pl-2 pointer"
            href={bet.contentLink}
            target="_blank"
            rel="noreferrer"
          >
            &#x1F517;
          </a>
        </div>
        <div className="font-small">
          &#x1F558;
          {currentTime <= bet.expiresAt ? (
            <span>
              <span className="pl-2 pr-1">Game expires in</span>
              {duration?.hours()} hrs {duration?.minutes()} mins
            </span>
          ) : (
            <span className="pl-2 pr-1">Game expired</span>
          )}
        </div>
        <div className="font-small text-secondary mb-3">
          &#x1F441;
          <span className="pl-2">
            {contentCount?.toLocaleString("en-GB") ||
              bet?.contentCount?.toLocaleString("en-GB") ||
              "0"}
          </span>
          <span
            className={
              currentTime > bet.expiresAt && !currentUser.admin ? "d-none" : ""
            }
          >
            {loading ? (
              <span className="pl-2 spinner-border spinner-border-sm"></span>
            ) : (
              <span className="pl-2 lead pointer" onClick={getCount}>
                &#x21bb;
              </span>
            )}
          </span>
        </div>
        <ReactPlayer
          url={bet.contentLink}
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
      <div className="my-2">
        {bet.options.map((option, idx) => (
          <div
            className="form-check pl-0 my-1"
            key={idx}
            onClick={(e) => copyToClipboard(option)}
          >
            <input
              className="form-check-input"
              type="radio"
              id={`${bet.id}-${option}`}
              name={bet.id}
              value={option}
              onChange={(e) => handleChange(e.target.value, idx)}
              checked={option === selectedOption}
              disabled={userBet && userBet.postId === bet.id}
            />
            <label className="form-check-label" htmlFor={`${bet.id}-${option}`}>
              {option}
            </label>
          </div>
        ))}
      </div>
      <JoinBetComponent
        selectedOption={selectedOption}
        bet={bet}
        currentUser={currentUser}
        userBet={userBet}
        saveUserBet={saveUserBet}
        loading={loading}
        setLoading={setLoading}
        claimAmount={claimAmount}
      />
      <StatsComponent
        bet={bet}
        currentUser={currentUser}
        totalUserBets={totalUserBets}
      />
    </>
  );
};

export default YoutubeComponent;
