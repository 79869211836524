import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { post } from "services";
import { update } from "firebase_config";
import { ENV } from "constants/env_keys";
import { Tables } from "constants/db_tables";

function CheckoutComponent({
  currentUser,
  rechargeAmount,
  setRechargeAmount,
  setPaymentIntent,
  setStepNumber,
  goBack,
}) {
  const [loading, setLoading] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(false);
  const history = useHistory();

  const saveCheckout = async () => {
    if (rechargeAmount < +ENV.MIN_DEPOSIT_AMOUNT) {
      toast.error(
        `Minimum deposit amount is ${ENV.CURRENCY}${ENV.MIN_DEPOSIT_AMOUNT}`
      );
      return null;
    }

    /* Deposit from withdrawal amount */
    if (withdrawalAmount) {
      setLoading(true);
      const depositAmount =
        Number(currentUser.depositAmount) + Number(rechargeAmount);
      const withdrawAmount =
        Number(currentUser.withdrawAmount) - Number(rechargeAmount);
      const res = await update(Tables.USERS, currentUser.id, {
        depositAmount,
        withdrawAmount,
      });
      if (res.status === 200) {
        toast.success(
          `${ENV.CURRENCY}${rechargeAmount}/- deposited successfully`
        );
        history.push("/app/settings");
      } else {
        toast.error("Something went wrong. Try again later");
      }
      return null;
    }
    setLoading(true);
    const res = await post("/transaction/checkout", {
      amount: rechargeAmount,
      currency: "GBP",
    });
    if (res.status === 201) {
      setPaymentIntent(res.data.data);
      setStepNumber(2);
    }
    setLoading(false);
  };

  return (
    <div className="container mt-3">
      <div className="form-group">
        <label className="form-label pb-1">Currency</label>
        <select
          className="form-control font-small"
          aria-label=".form-select-sm"
        >
          <option defaultValue>British Pound (£)</option>
        </select>
      </div>
      <div className="form-group">
        <label className="form-label pb-1">Amount</label>
        <input
          type="number"
          className="form-control"
          placeholder={ENV.MIN_DEPOSIT_AMOUNT}
          onChange={(e) => setRechargeAmount(e.target.value)}
          value={rechargeAmount}
        />
      </div>
      {currentUser.withdrawAmount >= rechargeAmount && (
        <div className="text-center">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="depositFromWithdrawal"
            onChange={(e) => setWithdrawalAmount(!withdrawalAmount)}
            checked={withdrawalAmount}
          />
          <label className="form-check-label" htmlFor="depositFromWithdrawal">
            Deposit {ENV.CURRENCY}
            {rechargeAmount} from withdrawal amount
          </label>
        </div>
      )}
      <div className="text-center">
        <button
          className="btn btn-sm btn-violet-outline pointer col-12 col-sm-5 m-sm-3 mt-2 py-2"
          onClick={saveCheckout}
          disabled={loading}
        >
          {loading ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span>{withdrawalAmount ? "Confirm" : "Next"}</span>
          )}
        </button>
        <button
          className="btn btn-sm btn-outline-secondary pointer col-12 col-sm-5 mx-0 mx-sm-2 my-2 py-2"
          onClick={goBack}
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  );
}

export default CheckoutComponent;
