import React from "react";

const PuzzleComponent = ({
  currentUser,
  setBetOptions,
  betContentLink,
  uploadImage,
  deleteImage,
}) => {
  return (
    <>
      <div className="mt-4">
        <label htmlFor="form-question">Game options:</label>
        <em className="font-small float-right">Must be ; separated</em>
        <input
          type="text"
          className="form-control"
          name="form-question"
          placeholder="Ex: 250,000 ; 300,000 ; None"
          onChange={(e) => setBetOptions(e.target.value)}
        />
      </div>
      <div className="mt-4">
        <label htmlFor="staticImage">Image: &nbsp; +</label>
        <input
          type="file"
          className="form-control-plaintext d-none"
          id="staticImage"
          onChange={(e) => uploadImage(e.target.files[0])}
          accept="image/*"
        />
        {betContentLink && (
          <div>
            <button
              className="btn btn-violet"
              onClick={(e) => deleteImage(betContentLink)}
            >
              Delete Image
            </button>
            <img
              src={betContentLink}
              className="camera-icon"
              alt="Image"
              width="100%"
              height="100%"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PuzzleComponent;
