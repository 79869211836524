import React from "react";
import _ from "lodash";

import { ENV } from "constants/env_keys";

const StatsComponent = ({ currentUser, bet, totalUserBets }) => {
  const potAmount = bet.potAmount
    ? bet.potAmount
    : totalUserBets
      ? _.sumBy(totalUserBets, (b) => {
        return b.amount;
      })
      : 0;
  return (
    <div className="d-flex flex-inline justify-content-around align-items-center font-small">
      <div className="text-center">
        <span className="text-violet">
          {bet.potAmount > 0 && bet.amount === 0 ? (
            "Free"
          ) : (
            <span>
              {ENV.CURRENCY + bet.amount} <div className="text-black">fee</div>
            </span>
          )}
        </span>
      </div>
      <div className="text-center">
        <span className="text-violet">{totalUserBets?.length || 0}</span> <br />
        player{totalUserBets?.length !== 1 && "s"}
      </div>
      <div className="text-center">
        <span className="text-violet">
          {ENV.CURRENCY}
          {potAmount}
        </span>{" "}
        <br />
        pot
      </div>
    </div>
  );
};

export default StatsComponent;
