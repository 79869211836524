import React from "react";
import "./style.css";

const DecideWinnersComponent = ({
  currentUser,
  bet,
  correctOption,
  setCorrectOption,
  saveCorrectOption,
  loading,
}) => {
  return (
    <div className="form-inline justify-content-center mt-3">
      <input
        className={`col-12 col-sm-7 form-control font-small ${
          bet.category === "timer" && "d-none"
        }`}
        type="text"
        id="correctOption"
        name="correctOption"
        placeholder="Enter option without spaces"
        onChange={(e) => setCorrectOption(e.target.value)}
      />
      {loading ? (
        <span className="pl-2 spinner-border spinner-border-sm"></span>
      ) : (
        <button
          className="btn btn-sm btn-violet mt-3 mt-sm-0 mx-1 mx-sm-3 col-5 col-sm-2"
          disabled={!correctOption && bet.category !== "timer"}
          onClick={saveCorrectOption}
        >
          Confirm
        </button>
      )}
      <button
        className="btn btn-sm btn-outline-secondary mt-3 mt-sm-0 mx-1 mx-sm-1 col-5 col-sm-2"
        onClick={(e) => setCorrectOption("")}
      >
        Cancel
      </button>
    </div>
  );
};

export default DecideWinnersComponent;
