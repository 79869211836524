import React, { useEffect } from "react";
import "./style.css";

import { StaticHeaderComponent } from "components";

const TncComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StaticHeaderComponent />
      <div className="container tnc-wrapper">
        <h3 className="page-title">Terms of service</h3>
        <div>
          Read our terms below to learn more about your rights and
          responsibilities as a PayGames user.
        </div>
        <hr />
        <p>
          This document is an electronic record in terms of Information
          Technology Act, 2000, allied Rules and Applicable Laws. This
          electronic record is generated by a Computer System and does not
          require any physical or Digital Signatures.
        </p>
        <p>
          This document is published in accordance with the provisions of Rule 3
          (1) of the Information Technology (Intermediaries Guidelines) Rules,
          2011 that require publishing the Rules and Regulations, Privacy Policy
          and Terms of Use for access or usage of paygames&apos;s website.
        </p>
        <ol>
          <li className="section">
            <span className="section-title">Contractual terms: </span>
            <span className="section-desctiption">
              These Terms of Use (which together with all Schedules hereto shall
              be referred to hereinafter as “Terms”), once accepted, shall
              constitute a legally binding and valid agreement between
              paygames.uk (referred to as “PayGames ” hereinafter) and yourself
              (the “User”), an individual, from within any country in the world
              and shall determine and govern the access of the User to and use
              of the paygames.uk,. This Agreement is effective as on the date
              that the User confirms acceptance of the Terms hereof and any
              accompanying Terms by clicking ‘Accept’ button or, where
              applicable, the date on which this Agreement is countersigned (the
              “Effective Date”). <br />
              BY INDICATING ACCEPTANCE BY CLICKING ON THE ACCEPT BUTTON BELOW,
              OR BY ACCESSING, OR USING PAYGAMES.UK, USER REPRESENTS THAT HE/SHE
              IS ELIGIBLE AND COMPETENT IN FACT AND LAW AS WELL AS IN TERMS
              HEREOF AND IS NOT OTHERWISE DISQUALIFIED FOR ANY REASON WHATSOEVER
              TO USE PAYGAMES.UK AND AGREES TO BE BOUND BY THESE TERMS OF USE.
            </span>
          </li>
          <li className="section">
            <span className="section-title">General terms: </span>
            <span className="section-desctiption">
              The User must be at least 16 years of age to be eligible and
              competent to use the PayGames Website and by indicating the User’s
              acceptance as above, the User has represented and confirmed as
              such. <br />
              <br />
              For purposes of these Terms of Use, the words &quot;he&quot;,
              &quot;she&quot;, “his”, “her” or other like terms shall refer to
              the User who is (1) party to these Terms of Use by virtue of
              having confirmed acceptance in the manner specified above and
              consequently uses the paygames website. Accordingly, the User
              represents and warrants for himself or herself, that he or she is
              fully competent under these Terms of Use and under Applicable Laws
              to enter into and to be bound by these Terms of Use and to comply
              with these Terms of Use. <br />
              <br />
              The User’s access to and use of the PayGames website constitutes
              the User’s agreement to be bound by these Terms, which establish a
              contractual relationship between the User and paygames. At any
              time following registration of the User, if the User does not
              agree to these Terms, the User shall not access or use the
              PayGames website and this Agreement shall be deemed to stand
              terminated forthwith without anything furthermore except as
              regards anything, action or deed done prior to such termination
              Any use or attempt to use the paygames.uk following such
              termination should be wrongful and is liable to be dealt with in
              accordance with law. Unless specified expressly to the contrary,
              these Terms expressly supersede any and all prior agreements or
              arrangements with the User. The User acknowledges and agrees that
              PayGames may immediately terminate these Terms with respect to the
              User, or generally cease offering or deny access to the
              paygames.uk or any portion thereof, at any time for any reason at
              its sole discretion and the User shall have no objection thereto
              nor claim or assert any right, entitlement or expectation
              concerning continuity of paygames.uk or uninterrupted access
              thereto. At all times, the Privacy policy and these Terms of Use
              along with any other document or terms accompanying the same or
              notified to be related thereto are to be read conjointly and
              collectively and shall constitute but one Agreement.
            </span>
          </li>
          <li className="section">
            <span className="section-title">
              Changes to these terms of use:{" "}
            </span>
            <span className="section-desctiption">
              At its sole discretion, PayGames and its administration reserves
              the right to add, amend, revise, alter or change in whatsoever
              manner or form deemed fit these Terms of Use at any time,
              including but not limited to any or all conditions of use as well
              as charges associated with the use of paygames.uk, if any.
              PayGames may, without however being obligated to do so, notify
              such changes as may be considered necessary by posting the revised
              Terms of Use at paygames.uk/tnc. It shall be the User’s
              responsibility at all times to ensure that he/she is aware of the
              current Terms of Use while using paygames.uk. Where any changes
              are made to these Terms of Use, such changes shall be effective
              immediately unless otherwise expressly stated. By continuing to
              use paygames.uk, the User agrees to confirm his/her acceptance in
              advance to any revisions that may be effected to these Terms of
              Use. Provided that the User shall be at liberty to signify non
              acceptance of any revisions by ceasing to use paygames.uk
              forthwith.
            </span>
          </li>
          <li className="section">
            <span className="section-title">
              User account; Changes to account settings; User permissions:{" "}
            </span>
            <span className="section-desctiption">
              User is responsible for maintaining the necessary confidentiality,
              secrecy, security and integrity of his/her account and password
              and for restricting access to his/her Computer, Computer System or
              Mobile Device. User agrees that he/she is responsible for all
              activities that occur under his/her account or password, including
              all activities that occur under any user name he/she grants access
              to his/her account. User will ensure that all users granted access
              to his/her account comply with these Terms and any non disclosure
              agreement User enters into with us. If the User makes changes to
              his/her settings or user permissions within the Portal, it may
              take some time for our system to process the change and User
              acknowledges that those changes may not take effect immediately.
            </span>
          </li>
          <li className="section">
            <span className="section-title">User obligations: </span>
            <span className="section-desctiption">
              As a condition precedent for the use of paygames.uk at all times,
              the User warrants that the use of paygames.uk shall not be for any
              purpose that is unlawful, illegal, immoral or otherwise prohibited
              by Applicable Laws or these Terms of Use. The User shall not use
              paygames.uk in any manner that could damage, disable, hinder,
              inhibit, or impair the PayGames Portal or interfere with any other
              party&apos;s use and enjoyment of paygames.uk. The User shall not
              obtain or attempt to obtain any material or information by any
              means not intended to be made available or not provided through
              paygames.uk. <br />
              <br />
              paygames.uk may contain SMS based communications, data sync
              service, application download services, chat support services,
              news groups, forums, communities, personal web pages, calendars,
              and/or other message or communication facilities designed to
              enable the User to communicate with PayGames, other Users of
              PayGames, or the public at large (collectively referred to as
              &quot;Communication Services&quot;). The User agrees to use the
              Communication Services only to post, send and receive messages and
              materials that are appropriate, allowed and permissible under the
              present Terms of Use and do not violate or offend any Applicable
              Laws and these Terms of Use or Privacy Policy. <br />
              <br />
              The User warrants and undertakes to report any misuse,
              misappropriation, unauthorized access, unauthorized usage, theft
              or any such incidents that the User is aware of in relation to
              paygames.uk immediately to PayGames and failure to do so shall
              render the User solely responsible and liable for any consequences
              arising out of or in relation to the same. The User agrees that
              he/she shall access and use paygames.uk strictly in accordance
              with these Terms of Use and in line with Applicable Laws at all
              times. The User further agrees that he/she shall host, display,
              upload, modify, publish, transmit, update or share any information
              that:
              <ul>
                <li>
                  Belongs to another person/user and to which the user does not
                  have any right to; Is grossly harmful, harassing, blasphemous,
                  defamatory, obscene, pornographic, paedophilic, libelous,
                  invasive of another’s privacy, hateful;, or racially,
                  ethnically objectionable, disparaging, relating to or
                  encouraging money laundering or gambling, or otherwise
                  unlawful in any manner whatsoever;Harms minors in any way;
                </li>
                <li>Harms minors in any way;</li>
                <li>
                  Infringes any patent, trademark, copyright or other
                  proprietary rights;
                </li>
                <li>Violates any law for the time being in force;</li>
                <li>
                  Deceives or misleads the addressee about the origin of such
                  messages or communicates ay information which is grossly
                  offensive in nature;
                </li>
                <li>Impersonate another person;</li>
                <li>
                  Contains software viruses or any other computer code, files or
                  programs designed to interrupt, destroy or limit the
                  functionality of any computer resource;
                </li>
                <li>
                  Threatens the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  cognizable offence or prevents investigation of any offence or
                  is insulting to any other nation;
                </li>
                <li>
                  Is opposed to the law of land of the nation where the User
                  resides.
                </li>
              </ul>
            </span>
          </li>
          <li className="section">
            <span className="section-title">Disclaimer: </span>
            <span className="section-desctiption">
              PayGames shall be entitled to investigate and prosecute, or caused
              to be investigated and/or prosecuted, any acts or omissions on
              part of the Users that are reasonably suspected to constitute
              violations of any of the above stated obligations to the fullest
              extent permitted by the law. PayGames may involve and cooperate
              with the concerned law enforcement authorities in prosecuting
              Users who violate these Terms of Use read with the Privacy Policy
              and User Agreement. <br />
              <br />
              There is no obligation on PayGames to monitor the Communication
              Services. However, PayGames reserves the right to review materials
              posted to a Communication Service or its use and to remove any
              materials at its sole discretion at any time and the User shall
              not be entitled to call the same into question or object thereto.
              PayGames reserves the right to suspend or terminate the access of
              the User who is suspected or found to have violated User
              Obligations under these Terms of Use or any or all of the
              Communication Services at any time without notice and the User
              shall not be entitled to call the same into question or object
              thereto. <br />
              <br />
              PayGames reserves the right, at all times, to disclose any
              information that is necessary or required under any Applicable
              Law, regulation, legal process or governmental request, or to
              otherwise edit, use, refuse to post or to remove any information
              or materials, in whole or in part, in paygames’s sole discretion
              and the User shall not be entitled to call the same into question
              or object thereto.
              <br />
              <br />
              PayGames takes no responsibility for the proper functioning or
              connectivity or reliability or availability of any networks or
              servers, or any communications that occur over such networks and
              servers. PayGames makes no representations or warranties as to the
              interoperability of paygames.uk with any network or servers. The
              User acknowledges that PayGames shall not be responsible or liable
              to the User or to any third party as a result of any networks or
              servers failing to perform in the manner intended or on account,
              failure of paygames.uk to properly operate with the network or
              servers or in the event of any errors occurring on the networks or
              servers of the User as a result of his/her use of paygames.uk.
              <br />
              <br />
              Materials uploaded to or by means of a Communication Service may
              be subject to posted limitations on usage, reproduction and/or
              dissemination. The User is solely responsible at all times for
              ensuring adherence to such limitations for downloading the
              materials.
              <br />
              <br />
              PayGames shall not be responsible for verifying the accuracy or
              authenticity or completeness or sufficiency or appropriation s of
              any personal information provided by the User in the event of any
              emergencies, incidents and communications. PayGames shall not be
              responsible or liable for any failure resulting out of any
              inaccuracy or incompleteness or insufficiency of the information
              provided by the User.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Communications monitoring: </span>
            <span className="section-desctiption">
              The User acknowledges that PayGames may communicate with the User
              primarily via email or by posting notices on the Portal. User
              agrees that all agreements, notices, disclosures and any other
              communications that PayGames provide to him/her electronically
              satisfy any legal requirements that such communications be in
              writing. To the maximum extent permitted by applicable law,
              paygames may monitor any electronic communications User makes in
              connection with the Portal and may disclose such information in
              the event it has a good faith reason to believe it is necessary
              for purposes of ensuring User’s compliance with this Agreement,
              and protecting the rights, property, and interests of PayGames or
              any third party.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Charges: </span>
            <span className="section-desctiption">
              Except to the extent stated herein, Membership of paygames.uk is
              currently free for Users. paygames.uk does not charge any fee for
              using the website. PayGames reserves the right to change its Fee
              Policy from time to time. In particular, PayGames may, at its sole
              discretion, introduce new services and modify some or all of the
              existing services offered on paygames.uk. In such an event
              paygames reserves the right to introduce fees for the new services
              offered or amend/introduce fees for existing services, as the case
              may be. Changes to the Fee Policy shall be posted on the Website
              or communicated via email and such changes shall automatically
              become effective immediately upon being posted on the Website or
              communicated otherwise. Unless otherwise stated, all fees shall be
              quoted in Great Britian Pounds. User shall be solely responsible
              for compliance of all Applicable Laws including those in Britian
              for making payments towards the Fees.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Non-disclosure: </span>
            <span className="section-desctiption">
              PayGames does not claim ownership of the materials provided to
              PayGames or posted, uploaded, inputted or submitted to paygames.uk
              including pictures, location, personal details, feedback and
              suggestions (&quot;Submissions&quot;). Except as may be otherwise
              be expressly stated, PayGames further warrants that no Submissions
              provided to PayGames shall be disclosed to any third party except
              in such cases where the User has explicitly authorized, requested,
              permitted PayGames to do the same or where required by Applicable
              Laws or governmental or judicial orders. The Privacy policy of
              paygames further describes and explains the conditions regarding
              collection, usage and disclosure of information collected or
              provided by the User.
            </span>
          </li>
          <li className="section">
            <span className="section-title">
              Copyright and Intellectual Property Policy:{" "}
            </span>
            <span className="section-desctiption">
              Copyright and Trademark of paygames- Unless expressly otherwise
              stated herein, the User acknowledges and understands that
              paygames.uk owns or licenses all content on the PayGames Website,
              including software, text, visual and audio content and such like
              (referred to together as &quot;Content&quot;) and PayGames
              trademarks, logos, and brand elements and such like (referred to
              as &quot;Marks&quot;) and the User represents and assures that
              he/she shall not do or cause to be done, anything that may be
              inconsistent with or violative of rights of PayGames in or to such
              Content or Marks. The Content and Marks are protected under
              Applicable Laws. The names of actual companies and products
              mentioned herein may be the trademarks of their respective owners
              and User accordingly represents and assures that he/she shall not
              do or cause to be done anything that may be inconsistent with or
              violative of such third party rights. Any rights not expressly
              granted herein are reserved. <br />
              <br />
              Infringers- PayGames respects the intellectual property rights, if
              any, of its Users. User may notify PayGames in writing, by email
              or mail to our designated agent listed below, if the User believes
              that another User of paygames.uk has infringed their intellectual
              property rights. The User acknowledges and agrees that upon
              receipt and notice of a claim of infringement, PayGames may
              immediately remove the identified materials from paygames.uk
              without liability. In such a case however, the User at whose
              notification or request any disputed material is removed from
              paygames.uk shall be liable to save, protect, hold harmless and
              indemnify PayGames from and against any liability, action, claim,
              demand, notices, costs, expenses, penalties, fines, proceedings
              etc that may arise in connection with such removal of the material
              from paygames.uk. <br />
              <br />
              To be effective the notification must include: identification of
              the copyrighted work claimed to have been infringed, or, if
              multiple copyrighted works are covered by a single notification, a
              representative list of such works; identification of the allegedly
              infringing material and information reasonably sufficient to
              permit PayGames to identify, trace and locate the material on the
              paygames.uk; information reasonably sufficient to permit PayGames
              to contact the User, such as an address, telephone number, and, if
              available, an email address; a statement by User that he/she has
              good faith belief that the disputed use is not authorized by the
              copyright owner, its agent, or the law; a statement by User, made
              under penalty of perjury, that the above information in
              User&apos;s notification is accurate and that User is the
              copyright owner or authorized to act on the copyright owner&apos;s
              behalf; User’s physical or electronic signature; and a clear
              statement by the User to the effect that the User undertakes to
              indemnify PayGames from and against any and all consequences of
              any reliance placed or act done by PayGames on the basis of the
              above said statements of the User with regard to disputed
              infringement. <br />
              <br />
              Counter-Notice by Accused User- In case PayGames removes any
              content, personal information or profile of any User deactivates,
              disables or terminates any Accused User’s account due to suspicion
              of copyright infringement on the basis of information received
              from User as above, the Accused User may dispute his/her
              responsibility for or otherwise the alleged infringement itself by
              sending a written communication by email or mail to the designated
              agent of PayGames as below. [ paygames@gmail.com ] That written
              communication must include the following: Accused User’s physical
              or electronic signature; Identification of the material that has
              been removed or to which access has been disabled and the location
              at which the material appeared before it was removed or access to
              it was disabled; a statement under penalty of perjury that the
              Accused User has good faith belief that the material was removed
              or disabled as a result of mistake or misidentification of the
              material to be removed or disabled; and Accused User’s name,
              address, and telephone number, and a statement that the User
              consents to his/her submission to the jurisdiction of the
              competent Court as provided for hereunder and that the User will
              accept service of process from the person who provided
              notification of copyright infringement or an agent of such person.{" "}
              <br />
              <br />
              The User must send all notices under the above copyright
              infringement policies by email or mail to the following designated
              paygames&apos;s agent for receipt of notifications of claimed
              infringement: <br />
              PayGames Attn: Copyright paygames@gmail.com <br />
              <br />
              Repeat Infringers- Without prejudice to any rights that PayGames
              may have under these Terms of and under the Applicable Laws,
              User’s account shall be terminated forthwith and without notice
              if, in the opinion of PayGames, such User is determined to be a
              repeat infringer. Repeat infringers are Users who have been the
              subject of more than one valid takedown request that has not been
              successfully rebutted.
            </span>
          </li>
          <li className="section">
            <span className="section-title">
              Disclaimers and limitation of liability:{" "}
            </span>
            <span className="section-desctiption">
              By using paygames.uk, the User acknowledges that he/she
              understands and agrees that to the maximum extent permitted by
              applicable law, the services of paygames.uk are provided on an
              &quot;as is&quot; and &quot;as available&quot; basis without
              warranty or condition of any kind, express or implied. PayGames
              and/or its agents or contractors hereby disclaim all warranties
              and conditions with respect to the services available at
              paygames.uk, including all implied warranties, guarantees or
              conditions of use, fitness for a particular purpose, title,
              non-infringement, and absence of errors, bugs, viruses,
              mechanisms, processes which may disable, damage or interfere with
              computer systems or networks used by the User to install, access
              or use paygames.uk hereunder.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Indemnification: </span>
            <span className="section-desctiption">
              The User agrees to indemnify and defend paygames (including its
              directors, officers, personnel, stakeholders, members) and its
              Affiliates from and against any and all claims, liabilities,
              damages, losses, costs, expenses, charges, fines, penalties,
              actions, demands, proceedings, fees of any kind (including
              reasonable attorneys&apos; fees and legal costs), arising from or
              relating to: (a) any information (including Submissions) that the
              User or anyone using User’s account submit, post, or transmit
              through the paygames.uk; (b) the use of the paygames.uk by the
              User or anyone using User’s account; (c) the violation of these
              Terms of Use by User or anyone using the User’s account; (d)
              paygames’s Submissions; or (e) the violation of any rights of any
              third party, including intellectual property, privacy, publicity,
              or other proprietary rights by the User or anyone using User’s
              account; (f) any wrongful, false, incorrect, misleading,
              incomplete, infringing information that is provided by the User;
              (g) any act of omission or commission of the User (including,
              where applicable, the User’s parent or legal guardian). PayGames
              reserves the right, at its own expense, to assume that the
              exclusive defense and control of any matter is subject to
              indemnification by the User. If paygames does assume the defense
              of such a matter, the User will reasonably cooperate with PayGames
              in such defense.
            </span>
          </li>
          <li className="section">
            <span className="section-title">
              Termination/access restriction:{" "}
            </span>
            <span className="section-desctiption">
              PayGames reserves the right, at all times to terminate access of
              the User to paygames.uk at any time, in its sole discretion, and
              without prior notice. No reason is required to be provided by
              PayGames for such termination. The User may terminate this
              Agreement by ceasing to use the paygames.uk.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Profanity policy: </span>
            <span className="section-desctiption">
              PayGames prohibits the use of language that is racist, hateful,
              sexual or obscene in nature in a public area and encourages and
              requests all Users to report any violations of its Policy and/or
              related conditions to PayGames for review:
              <ul>
                <li>Report offensive display names;</li>
                <li>
                  Report offensive language in a blog/discussion board/comment
                  board or otherwise.
                </li>
              </ul>
              Disciplinary action may result in the indefinite suspension of a
              User&apos;s account, temporary suspension, or a formal warning.{" "}
              <br />
              PayGames may consider the circumstances of an alleged policy
              violation and the user&apos;s records before taking action. <br />
              Violations of this policy may result in a range of actions,
              including:
              <ul>
                <li>Account suspension;</li>
                <li>Account termination;</li>
                <li>Prosecution under Applicable Law.</li>
              </ul>
            </span>
          </li>
          <li className="section">
            <span className="section-title">Privacy: </span>
            <span className="section-desctiption">
              PayGames respects privacy of the User at all times. The User is
              advised to read the Privacy Policy of paygames.uk that explains
              how PayGames collects, uses, and discloses information about the
              User. <br />
              <br />
              USER GRIEVANCE REDRESSAL MECHANISM: Any User who has any
              reasonable concerns with regard to paygames.uk is advised to
              immediately and by all means within 48 hours from the date of such
              concern write to the designated User Grievance Redressal Cell at
              [paygames@gmail.com] The User Grievance Redressal Cell shall
              endeavour to respond to the User within 10 working days or such
              further reasonable period notified by the User Grievance Redressal
              Cell to the User.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Notices: </span>
            <span className="section-desctiption">
              The User agrees that with respect any written notice which
              paygames is required or permitted to give to the User in
              connection with these Terms of Use, such notice shall be deemed to
              have been effectively given immediately upon electronic
              transmission of such notice to the email address of the User set
              forth in their account information. <br />
              <br />
              Except as otherwise expressly provided herein, all notices and
              communications to be addressed to PayGames with regard to these
              Terms of Use or the accompanying Privacy Policy shall be in
              writing and are to be addressed to the following address:
              paygames@gmail.com
            </span>
          </li>
          <li className="section">
            <span className="section-title">Force majeure: </span>
            <span className="section-desctiption">
              PayGames shall not be liable for any defect, delay, failure or
              inadequate performance of paygames.uk to the extent caused by a
              condition (for example, natural disaster, act of war or terrorism,
              riot, labor condition, governmental action, and Internet
              disturbance) that is beyond the reasonable control of paygames. In
              any such event, (a) PayGames shall provide a written notice to the
              User within 7 working days of the force majeure event coming into
              its knowledge, and (b) the User may immediately terminate this
              Agreement any time between the notification of the first force
              majeure event and thirty days after it receives such notice in
              subsection (a) in the event the force majeure event does not cease
              within 10 days from the date of the said notice.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Limitation of liability: </span>
            <span className="section-desctiption">
              In no event will PayGames be liable to USER or any party for,
              without limitation, any loss of use; interruption of business; or
              any direct; indirect; special; incidental; exemplary or punitive
              or consequential damages of any kind (including lost profits)
              regardless of the form of action whether in contract; tort
              (including negligence); strict product liability or otherwise;
              whether arising out of the use or inability to use paygames.uk
              (including but not limited to loss of data or data being rendered
              inaccurate), even if PayGames has been advised of the possibility
              of such damages.
            </span>
          </li>
          <li className="section">
            <span className="section-title">No waiver: </span>
            <span className="section-desctiption">
              Any delay, omission, forbearance or failure on part of PayGames to
              enforce any part or provision of this Agreement shall not
              constitute a waiver under any circumstances.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Severability: </span>
            <span className="section-desctiption">
              Notwithstanding that any part or provision of this Agreement is
              held or declared to be invalid and/or unenforceable by a competent
              court of law, the rest of the Agreement will remain in full force
              and effect.
            </span>
          </li>
          <li className="section">
            <span className="section-title">No agency: </span>
            <span className="section-desctiption">
              The Parties hereto are independent contractors, and this Agreement
              is not intended to create an agency, partnership or joint venture.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Equitable relief: </span>
            <span className="section-desctiption">
              Nothing in this Agreement will limit either party&apos;s ability
              to seek equitable relief.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Miscellaneous: </span>
            <span className="section-desctiption">
              These Terms of Use shall be governed by the laws of the Republic
              of India without regard to its conflicts of laws principles. The
              User hereby consents to the exclusive jurisdiction of competent
              Courts located at New Delhi, India with regard to all disputes
              arising out of or relating to the use of paygames.uk or these
              Terms of Use and Privacy Policy including their interpretation,
              applicability, scope, implementation, enforcement and the User
              hereby waives any right to claim that such courts constitute an
              inconvenient forum for such disputes. Use of paygames.uk is
              unauthorized in any jurisdiction that does not give effect to all
              provisions of these Terms of Use, including without limitation
              this paragraph. paygames&apos;s performance of these Terms of Use
              is subject to Applicable Laws, as amended from time to time, and
              legal processes, and nothing contained in these Terms of Use is in
              derogation of paygames&apos;s right to comply with governmental,
              court and law enforcement requests or requirements relating to the
              User’s use of paygames.uk or information provided to or gathered
              by paygames.uk with respect to such use. <br />
              <br />
              If any part of these Terms of Use is determined to be invalid or
              unenforceable pursuant to applicable law including, but not
              limited to, the warranty disclaimers and liability limitations set
              forth above, then the invalid or unenforceable provision will be
              deemed superseded by a valid, enforceable provision that most
              closely matches the intent of the original provision and the
              remainder of the agreement shall continue in effect. Unless
              otherwise specified herein, these Terms of Use together with the
              Privacy Policy constitutes the entire agreement between the User
              and paygames.uk with respect and it supersedes all prior or
              contemporaneous communications and proposals, whether electronic,
              oral or written, between the User and PayGames with respect to the
              paygames.uk <br />
              <br />
              Please print a copy of these Terms of Service for your records and
              PLEASE check the site frequently for any changes to these Terms of
              Service. <br />
              The provisions here of are to be read in conjunction with the
              Privacy Policy.
            </span>
          </li>
          <li className="section">
            <span className="section-title">Contacting PayGames: </span>
            <span className="section-desctiption">
              You may contact PayGames by email at letgtok@gmail.com
            </span>
          </li>
        </ol>
        Last updated on March 07, 2021
      </div>
    </>
  );
};

export default TncComponent;
